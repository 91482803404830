.Card {
    display: block;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    padding: 1em;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    text-align: right;
}

input[type=text], input[type=date], textarea {
    color: grey;
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    border-radius: 4px;
    box-sizing: border-box;
    text-align: right;
    appearance: none;
    font-family: "Helvetica", arial, sans-serif;
    font-size: 18px;
    border: 1px solid #ecf0f1;
    background: #ecf0f1;
    visibility: visible;
}

input[type=checkbox] {
    position: relative;
    cursor: pointer;
}

input[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background-color: #e9e9e9;
}

input[type=checkbox]:checked:before {
    content: "";
    display: block;
    position: absolute;
    width: 20px;
    height: 20px;
    top: 0;
    left: 0;
    background-color: #1E80EF;
}

input[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 6px;
}

#UserApproval {
    zcolor: black;
    background-color: rgba(0, 180, 0, 0.69);
//background-color: #65b0ff;
}

#mngRequestPurchase {
//color: black;
    background-color: rgba(0, 180, 0, 0.69);
//background-color: #65b0ff;
}

#BudgetSpreadshee {
//color: black;
    background-color: rgba(0, 120, 190, 0.69); //background-color: #65b0ff;
}

#ExpenseReporting {
//color: black;
    background-color: rgba(0, 120, 190, 0.69); //background-color: #65b0ff;
}

#financialReports {
//color: black;
    background-color: rgba(0, 120, 190, 0.69); //background-color: #65b0ff;
}
#financialReports1 {
//color: black;
    background-color: rgba(0, 120, 190, 0.69); //background-color: #65b0ff;
}

#ExpenseReporting1 {
//color: black;
    background-color: rgba(0, 120, 190, 0.69);
//background-color: #65b0ff;
}

#ChatResearcher {
//color: black;
    background-color: rgba(0, 120, 190, 0.69);
//background-color: #65b0ff;
}

#UpdateDetails {
//color: black;
    background-color: rgba(0, 120, 190, 0.69);
//background-color: #65b0ff;
}

.waiting-msg-counter-badge {
    width: 100%;
}
