.spreadsheet-cell-text-editor {
    appearance: none;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0px 6px 0 6px;
    border: 2px solid #ccc;
    vertical-align: top;
    color: var(--color);
    background-color: var(--background-color);
    font-family: inherit;
    font-size: var(--font-size);
}

.spreadsheet-cell-text-editor :focus {
    border-color: var(--selection-color);
    outline: none;
}

.spreadsheet-cell-text-editor ::placeholder {
    color: #999;
    opacity: 1;
}

.budget-files-list {
    overflow-y: scroll;
}

.budget-files-list .file-list-item-container {
    display: flex;

    justify-content: flex-start;
    align-items: center;
}

.file-list-item-container .index-text {
    margin-right: 10px;
}

.file-list-item {
    display: grid;
    margin-top: 20px;
    flex-grow: 1;

    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "budget-name creator date";
}

.file-list-item .budget-name {
    grid-area: budget-name;
}

.file-list-item .creator-text {
    grid-area: creator;
}

.file-list-item .creation-date {
    grid-area: date;
}