#registerBtn{
    color: black;
    background-color: #65b0ff;
}

#LoginBtn{
    color: black;
    background-color: rgba(0, 203, 0, 0.69);
}


#HomeBtn{
    color: black;
    background-color: rgba(203, 146, 133, 0.75);
}
